import { pick } from 'lodash-es'
import { STATUS_TYPE_NAME } from '#status/constant'
import type { StatusBase, StatusUpdatePayload, StatusSyncPayload } from '#status/types'
import { getIdentifier, createFieldModifiers } from '#core/utils/apollo'
import type { FieldsModifier } from '#core/types'

export const useStatusDataConvert = () => {
  const getStatusIdentifier = (id: string) => getIdentifier(id, STATUS_TYPE_NAME)
  const fields = ['name', 'type', 'position', 'settingsPackId']

  const getOptimisticStatus = (id: string | null, payload: StatusUpdatePayload, forFields = false): Partial<StatusBase> & { __typename?: string } => {
    const defaults = {}
    const status = getOptimisticObject(id, payload, fields, defaults, forFields)

    return forFields ? status : { ...status, __typename: STATUS_TYPE_NAME }
  }

  const getOptimisticStatusFields = (payload: StatusUpdatePayload) => {
    const status = getOptimisticStatus(null, payload, true)
    return convertObjectToFields(status)
  }

  const getSyncStatusFields = (payload: StatusSyncPayload) => {
    const fieldModifiers = createFieldModifiers(fields, payload)
    return pick(fieldModifiers, Object.keys(payload)) as FieldsModifier<StatusBase>
  }

  return {
    getStatusIdentifier,
    getOptimisticStatus,
    getOptimisticStatusFields,
    getSyncStatusFields,
  }
}

import type { ServerNotifyMessagePayload } from '#core/types/packages/socket'
import type { StatusBase, StatusSyncPayload } from '#status/types'
import { BOARD_CACHE, ModelListCache } from '#core/cache'
import { STATUS_LOADER_FRAGMENT } from '#status/loader_fragment'
import { STATUS_TYPE_NAME } from '#status/constant'

class StatusListCache extends ModelListCache<StatusBase> {
  constructor() {
    super(STATUS_TYPE_NAME)
  }

  override getBoardCacheKeys() {
    return [{
      key: BOARD_CACHE.DATA,
      subKey: 'statuses',
    }]
  }
}

export const useStatusSync = () => {
  const { client } = useApolloClient()
  const { currentBoard } = useWorkspaceSharedState()
  const listCache = new StatusListCache()
  const { getStatusIdentifier, getSyncStatusFields } = useStatusDataConvert()

  const onAdd = async (payload: StatusSyncPayload) => {
    client.cache.writeFragment({
      data: payload,
      fragment: STATUS_LOADER_FRAGMENT,
      fragmentName: 'StatusLoader',
    })

    listCache.add(currentBoard.value.id, payload)
  }

  const onUpdate = (payload: StatusSyncPayload) => {
    client.cache.modify({
      id: getStatusIdentifier(payload.id),
      fields: getSyncStatusFields(payload),
    })
    listCache.update(currentBoard.value.id, payload)
  }

  const onDelete = (payload: StatusSyncPayload) => {
    client.cache.evict({
      id: getStatusIdentifier(payload.id),
    })
  }

  const sync = (payload: ServerNotifyMessagePayload) => {
    const eventRegister = new Map<
      ServerNotifyMessagePayload['action'],
      (payload: StatusSyncPayload) => void
        >([
          ['ADD', onAdd],
          ['UPDATE', onUpdate],
          ['DELETE', onDelete],
        ])

    const eventHandler = eventRegister.get(payload.action)
    eventHandler?.(payload.model)
  }

  return {
    sync,
    listCache,
  }
}
